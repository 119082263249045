import react, { useEffect, useState, useRef } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import ListingShortcodeForm from "../ListingShortcodeForm";
import DoctorFilter from "./doctorfilter";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
let apiServices = new ApiService();
function Doctors() {
  const [doctorData, setDoctorData] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [countryData, setCountryData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [hospitalData, sethospitalData] = useState([])
  const [loading, setloading] = useState(true)
  const [metaTags , setMetaTags] = useState([])
  const { slug1 } = useParams();
  const didMountRef = useRef(true)
  const Navigate = useNavigate()
  useEffect(() => {
    if (didMountRef.current) {
      var datastring = {};
      if (slug1 !== undefined && slug1 != null && slug1.split('+').length > 0) {
        datastring = {
          filterdata: slug1.split('+')
        }
      } else {
        datastring = {}
      }
      apiServices.doctorslistPostRequest(datastring).then(res => {
        if (res.data.status === "success") {
          setMetaTags(res.data.metatags)
          setloading(false)
          setDoctorData(res.data.doctorsList)
          setImageUrl(res.data.doctor_url)
          setDefaultImage(res.data.default_image_baseurl)
        }
        else {
          setloading(false)
        }
      })
      apiServices.doctorcountrylistGetRequest().then(res => {
        if (res.data.length > 0) {
          setCountryData(res.data)
        }
      })
      apiServices.hospitalcategorylistGetRequest().then(res => {
        if (res.data.length > 0) {
          setCategoryData(res.data)
        }
      })
      // apiServices.doctorspecialitylistGetRequest().then(res => {
      //   if (res.data.status == 'success') {
      //     setCategoryData(res.data.doctorsSpecialityList)
      //   }
      // })
      apiServices.doctorhospitallistGetRequest().then(res => {
        if (res.data.status === 'success') {
          sethospitalData(res.data.doctorsHospitalList)
        }
      })
    }
    didMountRef.current = false;
  }, [])

  const searchbydoctorname=(name)=>{
    const dataString={
      search : name
    }
    apiServices.doctorsearchbynamePostRequest(dataString).then(res => {
      if (res.data.status === 'success') {
        setDoctorData(res.data.data)
        setDefaultImage(res.data.default_image_baseurl)
      }
    })
  }
  return (
    <>
     <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" itemprop="description" content={metaTags.desc != null ?metaTags.desc:"JDMax Healthcare"} />
        <meta name="keywords" content={metaTags.keyword ? metaTags.keyword:"JDMax Healthcare"} /> 
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={metaTags.title ? metaTags.title:"JDMax Healthcare"} />
        <meta property="og:image" content= ""/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content= {metaTags.desc != null ?metaTags.desc:"JDMax Healthcare"} />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={metaTags.title? metaTags.title: "JDMax Healthcare"} />
        <meta name="twitter:description" content={metaTags.desc != null ?metaTags.desc:"JDMax Healthcare"} />
        <meta property="twitter:image" content= ""/>
    </Helmet>
      <Header />
      <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
        <div className="container">
          <div className="row">
            <div className='col-lg-12'>
              <h1>Doctors</h1>
              <div className="breadcrumb-inner">
                <ul className="edu-breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="separator"><i className="icon-angle-right"></i></li>
                  <li className="breadcrumb-item">Doctors</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-gap-equal-small">
        <div className="container">
          <div className="row g-3">
            <DoctorFilter />
            <div className="col-lg-9">
              {/* <h1 className="page-title-h1">Doctors</h1> */}
              <div className="shortby">
                <div className="shortby-count">Showing {doctorData.length} entries</div>
                <div className="shortby-list">
                <ul class="header-action">
                                <li class="search-bar">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search By Doctor Name..." onChange={(e)=>{searchbydoctorname(e.target.value)}}/>
                                      
                                    </div>
                                </li>
                              
                            </ul>
</div>
              </div>
             
              {loading === true ? <>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div></>
                : <>
                  {doctorData.map((value, index) => {
                    if (index > 0 && (index + 1) % 5 === 0) {
                      return (
                        <>
                          <div className="hosbox doctors-list" key={index}>
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="hosbox-img" onClick={()=>Navigate(`/doctor/${value.doctor_slug}`)}>
                                  <img src={value.doctor_image != null ? imageUrl + value.doctor_image : defaultImage} className="img-fluid"></img>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="hosbox-content">
                                  <h2 className="hosbox-name"><a href={`/doctor/${value.doctor_slug}`}> {value.doctor_name}</a></h2>
                                  <p className="hosbox-loc"> {value.doctor_qualification}  </p>
                                  <div className="hosbox-list">
                                    <ul>

                                      <li>{value.speciality !== null ? <b>{value.speciality.speciality_name} |</b> : ""} <b> {value.doctor_designation} </b></li>
                                      {/* {value.doctor_designation != null ?
                                      <li><span>Designation :</span> {value.doctor_designation}</li> : ""} */}

                                      <li><span>Experience-</span>{value.doctor_experience} Years</li>
                                      {value.hospital != null ?
                                        <li><span style={{ display: "inline" }}>Works at-</span> {value.hospital.hospital_name}</li> : ""}
                                      {value.doctor_city_name != null && value.doctor_country_name != null && value.doctor_city_name != "" && value.doctor_country_name != ""
                                        ?
                                        <li>{value.doctor_city_name} , {value.doctor_country_name}</li> : ""}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="hosbox-content-footer">
                                  {/* <a href={"/free-consultation/2/"+value.doctor_id} className="edu-btn01 btn-small btn-full" style={{ marginBottom: "4px" }}> Free Consultation</a> */}
                                  <a href={"/free-consultation/2/"+value.doctor_id} className="edu-btn btn-small btn-full" style={{ marginBottom: "4px" }}>Book Appointment </a>
                                  <a href="https://wa.me/91" target="new" className="edu-btn03 btn-small btn-full">WhatsApp Us </a>
                                </div>
                              </div>
                            </div>

                          </div>
                          <ListingShortcodeForm />
                        </>
                      )
                    }
                    else {
                      return (
                        <div className="hosbox doctors-list" key={index}>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="hosbox-img"  onClick={()=>Navigate(`/doctor/${value.doctor_slug}`)}>
                                <img src={value.doctor_image != null ? imageUrl + value.doctor_image : defaultImage} className="img-fluid"></img>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="hosbox-content">
                                <h2 className="hosbox-name"><a href={`/doctor/${value.doctor_slug}`}> {value.doctor_name}</a></h2>
                                <p className="hosbox-loc"> {value.doctor_qualification}  </p>
                                <div className="hosbox-list">
                                  <ul>
                                    <li><b>{value.speciality !== null ? <b>{value.speciality.speciality_name} | </b> : ""}</b>  <b>{value.doctor_designation} </b></li>
                                    {/* {value.doctor_designation != null ?
                                      <li><span>Designation :</span> {value.doctor_designation}</li> : ""} */}
                                    <li><span>Experience-</span>{value.doctor_experience} Years</li>
                                    {value.hospital != null ?
                                      <li><span style={{ display: "inline" }}>Works at-</span> {value.hospital.hospital_name}</li> : ""}
                                    {value.doctor_city_name != null && value.doctor_country_name != null && value.doctor_city_name != "" && value.doctor_country_name != ""
                                      ?
                                      <li><span style={{ display: "inline" }}> </span>{value.doctor_city_name} , {value.doctor_country_name}</li> : ""}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="hosbox-content-footer">
                              <a href={"/free-consultation/2/"+value.doctor_id} className="edu-btn btn-small btn-full" style={{ marginBottom: "4px" }}>Book Appointment </a>
                                  <a href="https://wa.me/91" target="new" className="edu-btn03 btn-small btn-full">WhatsApp Us</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}
                </>
              }
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
export default Doctors;