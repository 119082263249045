import React, { useEffect, useState, useRef } from 'react';
import { ApiService } from '../Services/apiservices';
import {useLocation , useNavigate} from "react-router-dom"; 
let apiServices = new ApiService();
function Header() {
  const [sidebar, setSidebar] = useState('popup-mobile-menu')
  const [isActive, setActive] = useState("false");
  const [MenuData, setMenuData] = useState([]);
  const [TreatementCategories, setTreatementCategories] = useState([]);
  const [TreatementMenuData, setTreatementMenuData] = useState([]);
  const [imageUrl, setImageUrl] = useState('')
  const [settingData, setSettingData] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [header1, setHeader1] = useState('')
  const [Treatmentmenus, setTreatmentmenus] = useState([])
  const [medicalTreatmentActive , setMedicalTreatmentActive] = useState('')
  const [activeClass , setActiveClass] = useState("actice")
  const [userData , setUserData] = useState("")
  const didMountRef = useRef(true);
  const location = useLocation();
  const navigate = useNavigate()
  useEffect(() => {
    if (didMountRef.current) {
      const dealersessiondata = JSON.parse(localStorage.getItem('DEALER_SESSION'));
            if (dealersessiondata != null) {
              navigate("/")
            }
     const sessionData = JSON.parse(localStorage.getItem('USER_SESSION'))
     console.log(sessionData)
    setUserData(sessionData)
      const datastring = {};
     apiServices.medicaltreatementparentcatdataGetRequest().then(res => {
        if (res.data.status == "success") {
          setTreatementCategories(res.data.data)
        }
      })
      // apiServices.appearencemenusdataPostRequest(datastring).then(res => {
      //   if (res.data.status == "success") {
      //     setMenuData(res.data.data)
      //   }
      // })
      apiServices.populartreatmentGetRequest().then(res => {
        if (res.data.status == "success") {
          setTreatementMenuData(res.data.data)
        }
      })
      apiServices.settingsdataGetRequest().then(res => {
        if (res.data.status == "success") {
          setImageUrl(res.data.settings_url)
          setSettingData(res.data.sitesettings)
          setDefaultImage(res.data.default_image_baseurl)
        }
      })
      apiServices.headerGetRequest().then(res => {
        if (res.data.status == "success") {
          setHeader1(res.data.headerData.header_desc1)
        }
      })
      apiServices.treatmentmenusGetRequest().then(res => {
        if (res.data.category) {
          setTreatmentmenus(res.data.category)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  const openSideBar = () => {
    setSidebar("popup-mobile-menu active")
  }
  const closseSideBar = () => {
    setSidebar('popup-mobile-menu')
  }
  const handleToggle = () => {
    setActive(!isActive);
  };

  const medicaltreatment=(slug)=>{
    setMedicalTreatmentActive(slug)
}
  return (
    <>
      <header className="edu-header header-style-1 header-fullwidth">
        <div className="header-top-bar">
          <div className="container-fluid">
            <div className="header-top">
              <div className="header-top-left" dangerouslySetInnerHTML={{ __html: header1 }}>
                {/* <div className="header-notify">
                 Free Consultation <a href="/contact">Click Here!</a>
                </div> */}
              </div>
              <div className="header-top-right">
                <ul className="header-info">
                {userData != null ? 
                <li><i className="icon-user"></i><a href="/myaccount">{userData.user_first_name}</a></li> 
                 : <li><a href="/login">Login</a></li>}
                  {userData == null ? 
                  <li><a href="/register">Register</a></li>:''}
                  <li><a href={"tel:" + settingData.admin_support_mobile}><i className="icon-phone"></i>{settingData.admin_support_mobile}</a></li>
                  <li><a href={"mailto:" +settingData.admin_email} target="_blank"><i className="icon-envelope"></i>{settingData.admin_email} </a></li>
                  {/* <li className="social-icon"> */}
                  <li>
                  {
                    settingData.facebook_url!=null? 
                    <a href={settingData.facebook_url} target="new"><i className="icon-facebook"></i></a>
                    :null}
                  </li>
                  <li>
                    {
                    settingData.linkedin_url!=null?
                    <a href={settingData.linkedin_url} target="new"><i className="icon-linkedin2"></i></a>
                    :null}
                    </li>

                    <li>
                    {
                    settingData.instagram_url!=null?
                    <a href={settingData.instagram_url} target="new"><i className="icon-instagram"></i></a>
                    :null}
                 </li>
                  <li>
                    {
                    settingData.twitter_url!=null?
                    <a href={settingData.twitter_url} target="new"><i className="icon-twitter"></i></a>
                    :null}
                   </li>
                     <li>
                    {
                    settingData.youtube_url!=null?
                   <a href={settingData.youtube_url} target="new" className="color-yt"><i className="icon-youtube"></i></a>
                   :null}

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="edu-sticky-placeholder"></div>
        <div className="header-mainmenu">
          <div className="container-fluid">
            <div className="header-navbar">
              <div className="header-brand">
                <div className="logo">
                  <a href="/">
                    <img className="logo-light" src="/assets/images/logo.png" alt={settingData.logo} />
                    <img className="logo-dark" src="/assets/images/logo.png" alt={settingData.logo} />
                  </a>
                </div>
              </div>
              <div className="header-mainnav">
                <nav className="mainmenu-nav">
                  <ul className="mainmenu">
                    <li className="has-droupdown"><a href="#" className={location.pathname.includes("/treatment")? "active" :""}> Treatment</a>
                      <div className="mega-menu">
                        <div className="container">
                          <div className="row g-3">
                            <div className="col-lg-12">
                              <ul>
                                {TreatementCategories.map((value, index) => (
                                  <li><a href={'/treatment/' + value.category_slug} key={index} onClick={(e) => medicaltreatment(value.category_slug)}>{value.category_name}</a></li>
                                ))
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li><a href="/hospitals" className={location.pathname.includes("/hospitals") ?"active":""}>Hospitals</a></li>
                    <li><a href="/doctors" className={location.pathname.includes("/doctors") ?"active":""}>Doctors</a></li>
                    <li><a href="/video" className={location.pathname.includes("/video") ?"active":""}>Video</a></li>
                    <li><a href="/patient-testimonials" className={location.pathname.includes("/patient-testimonials") ?"active":""}>Patient Testimonial</a></li>
                    <li><a href="/packages" className={location.pathname.includes("/packages") ?"active":""}>Packages</a></li>
                    <li><a href="/blogs" className={location.pathname.includes("/blogs") ?"active":""}>News & Blog</a></li>
                    <li><a href="/contact" className={location.pathname.includes("/contact") ?"active":""}>Contact</a></li>
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <ul className="header-action">
                  {/* <li className="search-bar">
                   <div className="input-group">
                  <input type="text" className="form-control" placeholder="Search"/>
                  <button className="search-btn" type="button" ><i className="icon-2"></i></button>
                  </div>
</li>
<li className="icon search-icon">
<a href="javascript:void(0)" className="search-trigger">
<i className="icon-2" onClick={openSearchBar}></i>
</a>
</li> */}
                  <li className="header-btn">
                    <a href="/get-a-quote" className="edu-btn btn-medium">Get a FREE quote<i className="icon-4"></i></a>
                  </li>
                  <li className="mobile-menu-bar d-block d-xl-none">
                    <button className="hamberger-button" onClick={openSideBar}>
                      <i className="icon-54" ></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={sidebar} >
          <div className="inner">
            <div className="header-top">
              <div className="logo">
                <a href="/">
                  <img className="logo-light" src="/assets/images/logo.png" alt="Corporate Logo" />
                  <img className="logo-dark" src="/assets/images/logo.png" alt="Corporate Logo" />
                </a>
              </div>
              <div className="close-menu">
                <button className="close-button" onClick={closseSideBar}>
                  <i className="icon-73"></i>
                </button>
              </div>
            </div>
            <ul className="mainmenu">
              <li className="has-droupdown-menu">
                <div className='has-droupdown-menu-link'>

                  <a href="#">Medical Treatement</a>
                  <a className='link-icon' data-bs-toggle="collapse" href='#one' role="button" aria-expanded="false" aria-controls="collapseExample"><i className=" ri-arrow-down-s-fill"></i></a>
                </div>
                <ul className='has-mega-menu-sub collapse' id='one'>
                  {TreatementCategories.map((value, index) => (
                    <li>
                      <div className='has-droupdown-menu-link'>
                        <a href={'/treatment/' + value.category_slug}>{value.category_name}</a>
                      </div>
                    </li>
                  ))
                  }
                </ul>
              </li>
              <li><a href="/hospitals">Hospitals</a></li>
              <li><a href="/doctors">Doctors</a></li>
              <li><a href="/packages">Packages</a></li>
              <li><a href="/blogs">News & Blog</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
        </div>
        <div className="edu-search-popup">
          <div className="content-wrap">
            <div className="site-logo">
              <img className="logo-light" src="assets/images/logo/logo-dark.png" alt="Corporate Logo" />
              <img className="logo-dark" src="assets/images/logo/logo-white.png" alt="Corporate Logo" />
            </div>
            <div className="close-button" >
              <button className="close-trigger"><i className="icon-73"></i></button>
            </div>
            <div className="inner">
              <form className="search-form" action="#">
                <input type="text" className="edublink-search-popup-field" placeholder="Search Here..." />
                <button className="submit-button"><i className="icon-2"></i></button>
              </form>
            </div>
          </div>
        </div>
      </header>
      <div className="header-bottom">
        <div className="header-bottom-head collapsed" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Popular Treatements</div>
        <div className="header-bottom-content collapse" id="collapseExample">
          <ul>
            {(() => {
              return (
                TreatementMenuData.length > 0 && TreatementMenuData.map((subvalue, subindex) => {
                  if(subvalue.treatment != null && subvalue.treatment.length > 0)
                  {
                  return (
                    <li>
                      <a className="collapsed childclass" data-bs-toggle="collapse" href={subvalue.treatment != null && subvalue.treatment.length > 0 ?'#hy' + subindex:'/treatment/'+ subvalue.category_slug} role="button" aria-expanded="false" aria-controls="collapseExample">{subvalue.category_name}</a>
                      <ul className='collapse' id={'hy' + subindex}>
                        {subvalue.treatment != null && subvalue.treatment.length > 0 && subvalue.treatment.map((subsubvalue) => {
                            return (
                              <li><a>{subsubvalue.treatment_name}</a></li>
                            );
                        })
                        }
                      </ul>
                    </li>
                  );
                      }else{
                        return (
                          <li>
                            <a href={'/treatment/'+ subvalue.category_slug}>{subvalue.category_name}</a>
                          </li>
                        );
                      }
                })
              );
            })()}

            {/* <li>
    <a href="#" data-bs-toggle="collapse" href="#hy" role="button" aria-expanded="false" aria-controls="collapseExample">test</a>
      <ul className='collapse' id="hy">
        <li><a href="#"></a></li>
      </ul>
    </li> */}
          </ul>
        </div>
      </div>
      <div className='header-menu-bottom d-none d-md-block'>
        <div className="container">
          <div className='row'>
            <div className='col-lg-12'>
              <nav className="navbar navbar-expand-lg navbar-light">
                <ul className="navbar-nav mx-auto">
                  {(() => {
                    return (
                      Treatmentmenus.length > 0 && Treatmentmenus.map((value , index) => {
                        if (value.treatment.length > 0) {
                          return (
                            <li className="nav-item dropdown" key={index} onClick={(e) => medicaltreatment(value.category_slug)}>
                              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {value.category_name}
                              </a>
                              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {value.treatment.map((subvalue) => {
                                  return (
                                    <li><a className="dropdown-item" href={'/treatment/' +medicalTreatmentActive+"/"+ subvalue.treatment_slug}>{subvalue.treatment_name}</a></li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        } else {
                          return (
                            <li className="nav-item">
                              <a className="nav-link active" aria-current="page" href={'/treatment/' + value.category_slug}>{value.category_name}</a>
                            </li>
                          );
                        }
                      })
                    );
                  })()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Header